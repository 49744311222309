<template>
<div class="sm:block hidden">
  <el-dialog v-model="selectExamModal" title="Choisir un examen" width="60%" class=" w-full" center :before-close="closeModal" destroy-on-close>
  
    <div class=" flex flex-col items-center text-center text-sm px-3">   
      
        <div class="flex sm:flex-row flex-col justify-center items-start w-full">
            <div class="w-full">
                <div class="mb-2">
                    <p class="font-semibold text-sm text-gray-800 text-left ml-2">Choisir un module</p>
                </div>
                <el-select @change="loadExams" v-model="selectionm" placeholder="Veuillez sélectionner un module" class="w-full">
                    <el-option
                      v-for="item in modules"
                      :key="item.value"
                      :label="item.label"
                      :value="item.value"
                    >
                    </el-option>
                </el-select>
            </div>      
        </div>

        <div class="flex sm:flex-row flex-col justify-center items-start w-full mt-4">
          <el-table
            ref="singleTable"
            :data="examsSelected"
            style="width: 100%"
            height="200"
            highlight-current-row
            @current-change="handleExamChange"
          >
         
            <el-table-column label="Examens">
              <template #default="scope">
                 <p class="p-2">{{ scope.row.name }}</p>
              </template>
            </el-table-column>          
          </el-table>


        </div>
      
    </div>


    <template #footer>
      <span class="dialog-footer">
        <button @click="closeModal" class="py-2 px-5 mx-1 text-gray-500 border font-normal text-sm bg-white rounded-lg hover:shadow-lg transition duration-500 transform-gpu hover:scale-110 "> 
          Annuler
        </button>
        <button @click="createExam" class="py-2 px-4 mx-1 text-white font-normal text-sm bg-red-400 rounded-lg hover:shadow-lg transition duration-500 transform-gpu hover:scale-110 "> 
          Confirmer
        </button>
      
      </span>
    </template>
  </el-dialog>
</div>

<div class="sm:hidden block">
  <el-dialog v-model="selectExamModal" title="Choisir un examen" width="85%" class=" w-full" center :before-close="closeModal" destroy-on-close>
  
    <div class=" flex flex-col items-center text-center text-sm px-3">   
      
        <div class="flex sm:flex-row flex-col justify-center items-start w-full">
            <div class="w-full">
                <div class="mb-2">
                    <p class="font-semibold text-sm text-gray-800 text-left ml-2">Choisir un module</p>
                </div>
                <el-select @change="loadExams" v-model="selectionm" placeholder="Veuillez sélectionner un module" class="w-full">
                    <el-option
                      v-for="item in modules"
                      :key="item.value"
                      :label="item.label"
                      :value="item.value"
                    >
                    </el-option>
                </el-select>
            </div>      
        </div>

        <div class="flex sm:flex-row flex-col justify-center items-start w-full mt-4">
          <el-table
            ref="singleTable"
            :data="examsSelected"
            style="width: 100%"
            height="200"
            highlight-current-row
            @current-change="handleExamChange"
          >
         
            <el-table-column label="Examens">
              <template #default="scope">
                 <p class="p-2">{{ scope.row.name }}</p>
              </template>
            </el-table-column>          
          </el-table>
        </div>
      
    </div>


    <template #footer>
      <span class="dialog-footer">
        <button @click="closeModal" class="py-2 px-5 mx-1 text-gray-500 border font-normal text-sm bg-white rounded-lg hover:shadow-lg transition duration-500 transform-gpu hover:scale-110 "> 
          Annuler
        </button>
        <button @click="createExam" class="py-2 px-4 mx-1 text-white font-normal text-sm bg-red-400 rounded-lg hover:shadow-lg transition duration-500 transform-gpu hover:scale-110 "> 
          Confirmer
        </button>
      
      </span>
    </template>
  </el-dialog>
</div>

</template>

<script>
import axiosIns from '../../../plugins/axios';

export default {
  data () {
    return {
      
      modules : [],
      selectionm : null,
      
      selectedExam:null,
      // la liste des examens
      examsSelected : []
    }

  },
  
  props:{
    selectExamModal : Boolean,
    closeSelectExamModal: Function
  },

  mounted() {
    if (this.$store.getters.get_subscribeNow != null){
      if (this.Subscribemodules){
        axiosIns
          .get("/moduleofyear/" + this.$store.getters.get_subscribeNow)
          .then(({ data }) => {
            let empty = []
            data.modules.forEach(element => {
              if (this.SubscribtionModulesList.includes(element.id)){
                const item = {
                  label: element.title,
                  value: element.id
                }

                empty.push(item);
              }
            });
            this.modules = empty;
          })
          .catch(() => {
            this.isLoading = false;
          });
      }else{
        axiosIns
          .get("/moduleofyear/" +this.$store.getters.get_subscribeNow)
          .then(({ data }) => {
            let empty = []
            data.modules.forEach(element => {
                const item = {
                  label: element.title,
                  value: element.id
                }
                
                empty.push(item);
            });
            this.modules = empty;
          })
          .catch(() => {
            this.isLoading = false;
          });
    }
  }
  },

  methods: {
    closeModal(){
        this.closeSelectExamModal();
    },

    loadExams(){

      axiosIns
          .get("/examensofmodule/" +this.selectionm)
          .then(({ data }) => {
            this.examsSelected = data.examens.data;
          })
          .catch(() => {
            this.isLoading = false;
          });

      // pour récupérer la liste des exmens par rapport au module sélectionné
      // il ne faut pas oublier la pagination avec 10 examens par page
      // la liste des examens récupérer doit etre stocké dans :  this.examsSelected

     
    },


    handleExamChange(val) {
      this.selectedExam = {id : val.id , name : val.name };
    },

    createExam(){
      this.isLoading = true;
      axiosIns.post("/sessionexam",
      {
        id: this.selectedExam.id,
        user_ID: this.User.id,
        name : this.selectedExam.name
      }
    )
      .then(({ data }) => {
        // this.isLoading = false;
        this.$router.push("/dashboard/currentSession/?id="+data.result);
        this.closeSelectExamModal();
      })
      .catch(() => {
        // this.isLoading = false;
      });
    }


  },


  computed: {
    isToken() {
      return this.$store.getters.get_token;
    },
    User() {
      return this.$store.getters.get_user;
    },
    Subscribemodules() {
      return this.$store.getters.get_moduleSubscribed;
    },
    SubscribtionModulesList() {
      return this.$store.getters.get_subscriptionsModule;
    }
  },

}
</script>

<style>

</style>