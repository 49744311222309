<template>
    <div>
        <div class="fixed inset-0 z-30 bg-red-200" style="opacity: 0.5" aria-hidden="true"></div>
            <!--   add subscription modal   -->
        <div class="z-40 inset-0 fixed flex justify-center items-center">
          <div class=" flex flex-col w-16/12 sm:w-5/6 lg:w-1/2 max-w-md mx-auto rounded-lg border border-gray-300 shadow-xl">
            <div class=" flex flex-row justify-between items-center px-3 py-3 bg-white border-b border-gray-200 rounded-tl-lg rounded-tr-lg">
              <p class="font-semibold text-sm text-gray-800">Ajouter un abonnement</p>
              <svg @click="closeSub" class="w-4 h-4" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M6 18L18 6M6 6l12 12"></path>
              </svg>
            </div>
            <div class="flex flex-col px-6 py-5 bg-gray-50">

              <div :class="isLoading ? 'block' : 'hidden'" class="flex items-center justify-center h-full w-full">
                <!--<div class="fulfilling-square-spinner-show">
                  <div class="spinner-inner-show"></div>
                </div>-->
                <div class="loading">
                  <div class="dot"></div>
                  <div class="dot"></div>
                  <div class="dot"></div>
                  <div class="dot"></div>
                </div>
              </div>


              <div :class="isLoading ? 'hidden' : 'block'"  class=" flex flex-col sm:flex-row items-center text-center text-sm pb-4 pt-2 sm:space-x-5">   
                <div class="w-full">
                    <div class="flex sm:flex-row flex-col items-center w-full py-2">
                      <div :class="(subscribeMode == 'module') ? 'w-1/2' : 'w-full'"  class="flex flex-col sm:mx-2 ">
                        <div class="mb-1">
                          <p class="font-semibold text-sm text-gray-800 text-left ml-2">Mode d'abonnements</p>
                        </div>
                        <el-select @change="clearData()" v-model="subscribeMode" placeholder="Mode d'abonnements">
                          <el-option v-for="item in modesubscribe" :key="item.value" :label="item.label" :value="item.value">
                          </el-option>
                        </el-select>
                      </div>  
                      <div :class="(subscribeMode == 'module') ? 'block' : 'hidden'" class="flex flex-col sm:w-1/2 w-full sm:mx-2 sm:mt-0 mt-3">
                        <div class="mb-1">
                          <p class="font-semibold text-sm text-gray-800 text-left ml-2">Année de l'abonnement</p>
                        </div>
                        <el-select @change="loadModuleOfYear" v-model="subscription_module.year" placeholder="Année de l'abonnement">
                          <el-option v-for="item in options" :key="item.value" :label="item.label" :value="item.value">
                          </el-option>
                        </el-select>
                      </div>
                      
                    </div>

                    <div :class="(subscribeMode == 'module') ? 'block' : 'hidden'" class="flex sm:flex-row flex-col items-center py-2">
                    
                      <div class="flex flex-col sm:w-1/2 w-full sm:mx-2 sm:mt-0 mt-3">
                        <div class="mb-1">
                          <p class="font-semibold text-sm text-gray-800 text-left ml-2">Semaine(s) d'abonnements</p>
                        </div>
                        <el-input-number class="w-full" v-model="subscribeWeek" :min="0" :max="50"></el-input-number>
                      </div>
                      
                      <div class="flex flex-col sm:w-1/2 w-full sm:mx-2 sm:mt-0 mt-3">
                        <div class="mb-1">
                          <p class="font-semibold text-sm text-gray-800 text-left ml-2">Modules</p>
                        </div>
                        <el-select v-model="subscription_module.module_id" placeholder="Modules">
                          <el-option v-for="item in modules" :key="item.value" :label="item.label" :value="item.value">
                          </el-option>
                        </el-select>
                      </div>
                    </div>

                    <div :class="(subscribeMode == 'year') ? 'block' : 'hidden'" class="flex sm:flex-row flex-col items-center py-2">
                        <div :class="(subscription.type == 'yearsub') ? 'sm:w-1/2 w-full' : 'w-full'" class="flex flex-col sm:mx-2 sm:mt-0 mt-3">
                            <div class="mb-1">
                                <p class="font-semibold text-sm text-gray-800 text-left ml-2">Type d'abonnement</p>
                            </div>
                            <el-select @change="subscription.type === 'allsub' ? subscription.year = 0 : subscription.year = null" v-model="subscription.type" placeholder="Type d'abonnement">
                                <el-option
                                  v-for="item in types"
                                  :key="item.value"
                                  :label="item.label"
                                  :value="item.value"
                                >
                                </el-option>
                            </el-select>
                        </div>
                        <div :class="(subscription.type == 'yearsub') ? 'block' : 'hidden'" class="flex flex-col sm:w-1/2 w-full sm:mx-2 sm:mt-0 mt-3">
                            <div class="mb-1">
                                <p class="font-semibold text-sm text-gray-800 text-left ml-2">Année de l'abonnement</p>
                            </div>
                            <el-select v-model="subscription.year" placeholder="Année de l'abonnement">
                                <el-option
                                  v-for="item in options"
                                  :key="item.value"
                                  :label="item.label"
                                  :value="item.value"
                                >
                                </el-option>
                            </el-select>
                        </div>

                    </div>
                    

                    <div class="flex sm:flex-row flex-col items-center py-2">
                        <div class="flex flex-col sm:mx-2 sm:mt-0 mt-3 w-1/2">
                            <div class="mb-1">
                                <p class="font-semibold text-sm text-gray-800 text-left ml-2">Méthode de vente</p>
                            </div>
                            <el-select v-model="sellingMethod" placeholder="Méthode de vente">
                                <el-option
                                  v-for="item in typeselling"
                                  :key="item.value"
                                  :label="item.label"
                                  :value="item.value"
                                >
                                </el-option>
                            </el-select>
                        </div>
                        <div class="flex flex-col sm:w-1/2 w-full sm:mx-2 sm:mt-0 mt-3">
                            <div class="mb-1">
                                <p class="font-semibold text-sm text-gray-800 text-left ml-2">Nombre d'abonnements</p>
                            </div>
                            <el-input-number
                              class="w-full"
                              v-model="subscribeNumber"
                              :min="1"
                              :max="50"
                            ></el-input-number>
                        </div>
                        

                    </div>


                    <div :class="(subscribeNumber == 1) ? '' : 'flex justify-center'" class="flex sm:flex-row flex-col items-center py-2">
                        <div :class="(subscribeNumber == 1) ? 'block' : 'hidden'" class="flex flex-col sm:w-2/3 w-full sm:mx-2 sm:mt-0 mt-3">
                            <el-input v-model="code" placeholder="XXXX-XXXX-XXXX-XXXX" disabled  />
                        </div>
                        <div class="flex flex-col sm:w-1/3 w-full sm:mx-2 sm:mt-0 mt-3">
                           <button @click="generateNcode(subscribeNumber)" class="inline-flex py-2 px-4 text-white font-normal text-sm bg-red-400 rounded-md hover:shadow-lg transition duration-500 transform-gpu hover:scale-110 ">Génerer code<span :class="(subscribeNumber == 1) ? 'hidden' : 'block'">s</span></button>
                        </div>
                  </div>

                  
                </div>
              </div>
            </div>
            <div
              class=" flex flex-row items-center justify-between px-6 py-3 bg-white border-t border-gray-200 rounded-bl-lg rounded-br-lg "
            >
              <button
                @click="closeSub"
                class="font-semibold text-gray-600 text-sm"
              >
                Annuler
              </button>
              <button @click="confirmeCode" class=" text-sm px-4 text-red-400 font-semibold bg-white rounded"
              >
                Confirmer
              </button>
            </div>
          </div>
        </div>
    </div>
</template>
<script>

import { ElMessage } from 'element-plus'
import { h } from 'vue'
import axiosIns from '../../../plugins/axios';
export default {
    data () {
        return {
            isLoading :false,
            subscription: {
                type: null,
                year: null,
            },
            subscription_module: {
            year: null,
            module_id: null,
            },
            modules : [],
            code: null,
            codes : [],
            options: [
                {
                  value: '1',
                  label: '1 Année',
                },
                {
                  value: '2',
                  label: '2 Année',
                },
                {
                  value: '3',
                  label: '3 Année',
                },
                {
                  value: '4',
                  label: '4 Année',
                },
                {
                  value: '5',
                  label: '5 Année',
                },
                {
                  value: '6',
                  label: '6 Année',
                }

            ],

            types: [
                {
                  value: 'allsub',
                  label: 'Résidanat',
                },
                {
                  value: 'yearsub',
                  label: 'Abonnement annuel',
                },
            ],

            typeselling: [
                {
                  value: 'online',
                  label: 'En ligne',
                },
                {
                  value: 'out',
                  label: 'Guichet',
                },
            ],
          modesubscribe: [
            {
              value: 'year',
              label: 'Par année',
            },
            {
              value: 'module',
              label: 'Par module',
            },
          ],
            subscribeWeek : 0,
            subscribeNumber: null,
            sellingMethod : null,
            subscribeMode: null,
            test: null
        
        }
    },

    props: {
        closeAddSubscriptionPanel : Function
    },

    methods: {
      clearData(){
        this.subscribeWeek = 0;
        this.subscription.year = null;
        this.subscription.type = null;
        this.subscription_module.module_id = null;
        this.subscription_module.year = null;
      },
      loadModuleOfYear() {
        this.subscription_module.year
        this.isLoading = true;
        axiosIns.get("/moduleofyear/" + this.subscription_module.year)
          .then(({ data }) => {
            var mod = data.modules;

            let temps = []
            if (data.modules.length != undefined) {
              data.modules.forEach(element => {

                const item = {
                  label: element.title,
                  value: element.id
                }
                temps.push(item);
              });
            } else {
              const item = {
                label: mod[Object.keys(mod)[0]].title,
                value: mod[Object.keys(mod)[0]].id
              }
              temps.push(item);
            }

            this.modules = temps;
            this.isLoading = false;

          })
          .catch(() => {
            this.isLoading = false;
          });
        },

        closeSub(){
            this.closeAddSubscriptionPanel();
            this.subscription.type = null;
            this.subscription.year = null;
            this.subscription_module.year = null;
            this.subscription_module.module_id = null;
            this.subscribeMode = null;
            this.subscribeWeek = null;
            this.modules = null ;
            this.code = null;
            this.codes = [];
            this.subscribeNumber = null;
            this.sellingMethod = null ;
            // console.log(window.AddSubscriptionModal);
        },

        generateCode(){
		      var d = new Date().getTime();
	        if( window.performance && typeof window.performance.now === "function" )
            {
		        d += performance.now();
	        }
            var uuid = 'xxxx-xxxx-xxxx-yxxx'.replace(/[xy]/g, function(c)
	        {
	        	var r = (d + Math.random()*16)%16 | 0;
	        	d = Math.floor(d/16);
	        	return (c=='x' ? r : (r&0x3|0x8)).toString(16);
	        });
            // this.code = uuid;
            return uuid;
        },

        generateNcode(input){
          if(input == 1){
            this.codes = [];
            this.codes.push(this.generateCode());
            this.code = this.codes[0];
          }else{
            this.code = null;
            this.codes = [];
            var n = input; 
            for(var i=0;i<n;i++){
              var code = this.generateCode()
              if(this.codes.includes(code)){
                n++;
              }else{
                this.codes.push(code);
              }
            }
            
          }
        },

        confirmeCode() {
            this.isLoading = true;
            axiosIns.post("/subscribe",
              {
                type: this.sellingMethod,
                year: this.subscription.year,
                Activation_code : this.codes,
                module_id : this.subscription_module.module_id,
                mode : this.subscribeMode,
                week_number: this.subscribeWeek
              }
            )
            .then(({data}) => {
              this.closeSub();
              this.test = data.created;
              this.addsubscribe(data.created);
              this.isLoading = false;
            })
            .catch(() => {
                this.isLoading = false;
            });
        },
    },

    computed: {
        isToken() {
            return this.$store.getters.get_token;
        },
        User() {
          return this.$store.getters.get_user;
        },
    },

    setup() {
      const addsubscribe = (number) => {
        ElMessage({
          message: h('p', { style: 'color: #65a30d' }, [
            h('span', { style: 'font-weight: bold' }, number),
            h('span', null , ' Abonnement(s) crée(s) avec succès'),
          ]),
          // message: number+' Abonnement(s) crée(s) avec succès',
          type: 'success',
        })
      }
       return {
         addsubscribe,
        }
    }
    
}
</script>

<style>
.fulfilling-square-spinner-show,
.fulfilling-square-spinner-show * {
  box-sizing: border-box;
}

.fulfilling-square-spinner-show {
  height: 70px;
  width: 70px;
  position: relative;
  border: 4px solid #f87171;;
  animation: fulfilling-square-spinner-animation 4s infinite ease;
}

.fulfilling-square-spinner-show .spinner-inner-show {
  vertical-align: top;
  display: inline-block;
  background-color: #f87171;
  width: 100%;
  opacity: 1;
  animation: fulfilling-square-spinner-inner-animation 4s infinite ease-in;
}

@keyframes fulfilling-square-spinner-animation {
  0% {
    transform: rotate(0deg);
  }

  25% {
    transform: rotate(180deg);
  }

  50% {
    transform: rotate(180deg);
  }

  75% {
    transform: rotate(360deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

@keyframes fulfilling-square-spinner-inner-animation {
  0% {
    height: 0%;
  }

  25% {
    height: 0%;
  }

  50% {
    height: 100%;
  }

  75% {
    height: 100%;
  }

  100% {
    height: 0%;
  }
}
</style>