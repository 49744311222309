<template>
<div class="sm:block hidden">
  <el-dialog v-model="createPlaylistDialogVisible" title="Ajouter une playlist" width="40%" center :before-close="closeModal" destroy-on-close>
    <el-input placeholder="Donnez un nom à votre playlist" v-model="name"/>
    <template #footer>
      <span class="dialog-footer">
        <button @click="closeModal" class="py-2 px-4 mx-1 sm:my-0 my-1 text-gray-500 border font-normal text-sm bg-white rounded-lg hover:shadow-lg transition duration-500 transform-gpu hover:scale-110 "> 
          Annuler
        </button>
        <button @click="AddPlaylist"  class="py-2 px-5 mx-1 sm:my-0 my-1 text-white font-normal text-sm bg-red-400 rounded-lg hover:shadow-lg transition duration-500 transform-gpu hover:scale-110 "> 
          Valider
        </button>
      </span>
    </template>
  </el-dialog>
</div>
<div class="sm:hidden block" >
  <el-dialog v-model="createPlaylistDialogVisible" title="Ajouter une playlist" width="85%" center :before-close="closeModal" destroy-on-close>
    <el-input placeholder="Donnez un nom à votre playlist" v-model="name"/>
    <template #footer>
      <span class="dialog-footer">
        <button @click="closeModal" class="py-2 px-4 mx-1 sm:my-0 my-1 text-gray-500 border font-normal text-sm bg-white rounded-lg hover:shadow-lg transition duration-500 transform-gpu hover:scale-110 "> 
          Annuler
        </button>
        <button @click="AddPlaylist"  class="py-2 px-5 mx-1 sm:my-0 my-1 text-white font-normal text-sm bg-red-400 rounded-lg hover:shadow-lg transition duration-500 transform-gpu hover:scale-110 "> 
          Valider
        </button>
      </span>
    </template>
  </el-dialog>
</div>
</template>

<script>
import axiosIns from '../../../plugins/axios';

export default {
  data () {
    return {
      name: null,
    }
  },

  
  props:{
    createPlaylistDialogVisible : Boolean,
    closeCreatePlaylistModal: Function,
  },

  methods: {
    closeModal(){
      this.closeCreatePlaylistModal()
    },

    AddPlaylist(){
      axiosIns.post("/sessionplaylist",{
          user_ID:this.User.id,
          name : this.name
        })
        .then(() => {
          // this.isLoading = false;
          this.closeCreatePlaylistModal()
          
        })
        .catch(() => {
          // this.isLoading = false;
          this.closeCreatePlaylistModal()
        });
    }
  },


  computed: {
    isToken() {
      return this.$store.getters.get_token;
    },
    User() {
      return this.$store.getters.get_user;
    },
  },

}
</script>

<style>

</style>